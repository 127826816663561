import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '@/views/Login.vue'
import Dashboard from '@/views/Dashboard/index.vue'
import InvoiceReport from '@/views/InvoiceReport/index.vue'
import TranslationPage from '@/views/Translation.vue'
import OrderRoutes from './order'
import NotFound from '@/views/404.vue'

import { store } from '@/store'
import { CHECK_AUTH } from '@/store/actions.type'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/dashboard',
    alias: '/',
    name: 'dashboard',
    component: Dashboard
  },
  {
    path: '/invoice-reports',
    //alias: '/documents',
    name: 'invoice-reports',
    component: InvoiceReport
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    async beforeEnter(to, from, next) {
      if (store.getters.isAuthenticated) {
        next({ name: 'dashboard' })
      } else {
        next()
      }
    },
  },
  {
    path: '/translations',
    name: 'translations',
    component: TranslationPage,
  },
  {
    path: '/inbox/:inboxId',
    component: () => import(/* webpackChunkName: "order" */ '@/views/Inbox.vue'),
    children: [
      {
        name: 'inbox',
        props: true,
        path: ':search?', // selected tab by default
        component: () => import(/* webpackChunkName: "order" */ '@/components/Table/Orders.vue')
      },
      OrderRoutes
    ]
  },
  {
    path: '*',
    name: '404',
    component: NotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'login') {
    store.dispatch(CHECK_AUTH, { instance: router.app })
      .then(next)
      .catch(() =>
        router.push({ name: 'login' })
          .catch((e) => console.error(e))
      )
  } else {
    next()
  }
})

export default router
