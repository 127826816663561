import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import TokenService from '@/services/token'
import { base64StringToBlob } from 'blob-util'

const ApiService = {
  init() {
    Vue.use(VueAxios, axios)
    Vue.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL

    Vue.axios.interceptors.response.use(undefined, function (err) {
      return new Promise(() => {
        if (err.response?.status && err.response.status === 403 && err.response.config &&
          !err.response.config.__isRetryRequest) {
          // user is not allowed to be here. redirect him to idp
          window.location.href = process.env.VUE_APP_BASE_URL
        }
        throw err
      })
    })

    if (TokenService.getToken()) {
      this.setAuthToken(TokenService.getToken())
    }
  },
  setAuthToken(token?: string) {
    Vue.axios.defaults.headers.common.Authorization = token ? `Bearer ${token}` : null
  },



  query(resource: string, params?: any) {
    return Vue.axios.get(resource, params)
  },
  get(resource: string, slug = '', options = {}) {
    const path = slug ? `${resource}/${slug}` : `${resource}`
    return Vue.axios.get(path, options)
  },
  post(resource: string, params: object, options = {}) {
    return Vue.axios.post(resource, params, options)
  },
  // helper function to convert a b64 encoded image into a blob
  convertToBlob(b64Str) {
    const split = b64Str.split(',')
    return base64StringToBlob(split[1], 'image/jpeg')
  },
  download(resource, params) {
    return Vue.axios({
      url: resource,
      params: params,
      method: 'GET',
      responseType: 'blob',
    })
  },
}

export default ApiService
