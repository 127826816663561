<template>
  <v-menu left bottom>
    <template v-slot:activator="{ on, attrs }">
      <Button icon v-on="on" v-bind="attrs">
        <v-icon v-text="'mdi-flag-variant'" />
      </Button>
    </template>

    <v-list dense>
      <v-subheader
        v-text="$t('app.languageMenu.changeLanguage')"
        class="text-subtitle-1"
      />
      <v-list-item
        v-for="(value, key) in languages"
        :key="`lang-${key}`"
        @click="changeLanguage(key)"
      >
        <v-list-item-avatar>
          <country-flag :country="getFlagCode(key)" size="normal" />
        </v-list-item-avatar>
        <v-list-item-title>{{ value }}</v-list-item-title>
        <v-list-item-icon>
          <v-icon v-if="$i18n.locale === key" v-text="'mdi-check-bold'" />
        </v-list-item-icon>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import CountryFlag from 'vue-country-flag'
import Languages from '@/configs/languages'
import { LangSwitcher } from '@huesgesone/idp-npm-packages'

export default {
  name: 'app-language-menu',
  components: {
    CountryFlag,
  },
  methods: {
    changeLanguage(value) {
      console.log(this.$i18n.locale)
      LangSwitcher.loadMessages(this.$i18n, value)
      this.$i18n.locale = value
      console.log(this.$i18n.locale)
      this.showSuccess(this.$t('message.languageChanged'))
    },
    getFlagCode(lang) {
      switch (lang) {
        case 'en':
          return 'gb'
        case 'da':
          return 'dk'
        case 'nl_be':
          return 'be'
        default:
          return lang
      }
    }
  },
  computed: {
    languages() {
      return Languages
    }
  }
}
</script>
