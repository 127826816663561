import ApiService from './base'

export default {
  query(resource: string, params?: any) {
    return ApiService.query('/api/inboxes', params)
  },
  getInboxes() {
    return ApiService.get('/api/inboxes')
  },
  getInbox(inboxId: number) {
    return ApiService.get(`/api/inboxes/${inboxId}`)
  }
}