export default {
  orders: {
    orderId: 'Order',
    orderType: 'Type',
    licensePlate: 'License plate',
    vin: 'Vin',
    contractNumber: 'Contract nr.',
    creationDate: 'Created',
    openOrderTooltip: 'Open order',
    inboxSearchLabel: 'Search current inbox...',
    status: 'Status',
    notLockedTooltip: 'This order is unlocked',
    lockedTooltip: 'This order is currently opened by user \'{user}\'',
    alreadyOpenTooltip: 'This order is currently locked by you.',
    productName: 'Product name',
    maker: 'Maker',
    model: 'Model',
    inspectionplace: 'Inspectionplace',
    inspectionname: 'Locationname'
  },

  files: {
    mime: 'Mime',
    name: 'Filename',
    type: 'Type',
    uploadedOn: 'Uploaded',
    fileTypeLabel: 'File type',
    deleteFileTooltip: 'Delete this file',
    downloadFileTooltip: 'Download this file'
  },

  invoiceReports: {
    filename: 'Name',
    from: 'time from',
    to: 'time to',
    interval: 'interval',
    type: 'type',
  },
  actions: 'Actions',
}
