<template>
  <v-card v-bind="$attrs" v-on="$listeners" :flat="flat" :tile="tile">
    <slot></slot>
  </v-card>
</template>

<script>
export default {
  props: {
    flat: {
      type: Boolean,
      default: true
    },
    tile: {
      type: Boolean,
      default: true
    }
  }
}
</script>
