<template>
  <v-list dense nav color="primary" class="darken-2">
    <v-subheader v-text="`${$t('order.id')}: ${orderId}`" class="title"/>

    <v-list-item v-for="item in links" :key="item.title" :to="item.to">
      <v-list-item-icon>
        <v-icon v-text="item.icon" />
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title v-text="item.title" />
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  name: 'order-sidebar',
  computed: {
    links() {
      return [
        {
          title: this.$t('order.nav.dataLabel'),
          icon: 'mdi-car-info',
          to: { name: 'order.data', params: { orderId: this.orderId } }
        },
        {
          title: this.$t('order.nav.disposeLabel'),
          icon: 'mdi-calendar',
          to: { name: 'order.dispose', params: { orderId: this.orderId } }
        },
        {
          title: this.$t('order.nav.documentsLabel'),
          icon: 'mdi-cloud-upload',
          to: { name: 'order.documents', params: { orderId: this.orderId } }
        },
        {
          title: this.$t('order.nav.commentsLabel'),
          icon: 'mdi-comment-multiple',
          to: { name: 'order.comments', params: { orderId: this.orderId } }
        },
        {
          title: this.$t('order.nav.historyLabel'),
          icon: 'mdi-history',
          to: { name: 'order.history', params: { orderId: this.orderId } }
        }
      ]
    },
    orderId() {
      return this.$route.params.orderId
    }
  },
}
</script>
