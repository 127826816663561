export default {
  logout: 'Logout',
  languageMenu:{
    changeLanguage:'Change language',
  },
  orderSearch: {
    noOrdersFound: 'No orders can be found for the current query',
    label: 'Search orders...',
    order: 'Order',
    indicator: 'License plate',
    vin: 'Vin',
    product: 'Product',
    created: 'Created'
  }
}