<template>
  <SelectField
    v-bind="$attrs"
    v-on="$listeners"
    :label="locationsLabel"
    :hint="locationsHint"
    item-value="name"
    return-object
    filled
    persistent-hint
  >
    <template v-slot:selection="{ item }">
      <span>{{ item.name }}</span>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title v-text="item.name" />
        <v-list-item-subtitle v-text="getAddressName(item)" />
        <v-list-item-subtitle v-text="getAddress(item)" />
      </v-list-item-content>
    </template>
    <template #prepend-inner
      ><v-icon v-text="'mdi-map-marker-multiple-outline'" />
    </template>
  </SelectField>
</template>

<script>
export default {
  name: 'form-address-selector',
  computed: {
    locationsLabel() {
      return this.$t('form.address.locationsLabel')
    },
    locationsHint() {
      return this.$t('form.address.locationsHint')
    }
  },
  methods: {
    getAddressName(item) {
      return `${item.firstname || '-'} ${item.lastname || '-'}`
    },
    getAddress(item) {
      return `${item.address || '-'} ${item.addressnr || '-'}, \
      ${item.zipcode || '-'} ${item.city || '-'}`
    }
  }
}
</script>