<template>
  <v-form ref="form" :errors="errors" v-model="valid">
    <v-scroll-y-transition>
      <ErrorAlert class="mt-2" v-if="errors" :errors="errors.errors" />
    </v-scroll-y-transition>
    <v-card dense flat>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="6">
            <Autocomplete
              v-model="order.customer"
              :items="customers"
              :loading="customersLoading"
              :label="customerLabel"
              :rules="rules.required"
              item-text="name"
              item-value="id"
              @change="fetchProducts"
            />
          </v-col>
          <v-col cols="12" md="6">
            <Autocomplete
              v-model="order.product"
              :items="products"
              :loading="productsLoading"
              :label="productLabel"
              :rules="rules.required"
              item-text="name"
              item-value="id"
              @change="fetchAddresses"
          /></v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card dense flat v-if="order.customer && order.product">
      <v-card-title>
        <v-icon left v-text="'mdi-car'" />
        <span v-text="$t('form.order.vehicleInformation')" />
      </v-card-title>
      <v-card-text>
        <v-fade-transition>
          <v-row dense>
            <v-col cols="12" md="4">
              <TextField
                v-model="order.contractNumber"
                :label="contractNumberLabel"
            /></v-col>

            <v-col cols="12" md="4">
              <TextField
                v-model="order.damageNumber"
                :label="damageNumberLabel"
            /></v-col>

            <v-col cols="12" md="4">
              <TextField
                v-model="order.inventoryNumber"
                :label="inventoryNumberLabel"
            /></v-col>

            <v-col cols="12" md="4">
              <AddressPicker
                v-model="order.inspectionLocation"
                :label="inspectionLocationsLabel"
                :customerId="order.customer"
                type="inspection"
              />
            </v-col>

            <v-col cols="12" md="4">
              <AddressPicker
                v-model="order.reportAddress"
                :label="reportAddressLabel"
                :customerId="order.customer"
                type="report"
              />
            </v-col>

            <v-col cols="12" md="4">
              <AddressPicker
                v-model="order.invoiceAddress"
                :label="invoiceAddressLabel"
                :customerId="order.customer"
                type="invoice"
              />
            </v-col>

            <v-col cols="12" md="4"
              ><DatePickerField
                :label="desiredDateLabel"
                v-model="order.desiredDate"
              />
            </v-col>

            <v-col cols="12" md="4"
              ><DatePickerField :label="dueDateLabel" v-model="order.dueDate"
            /></v-col>

            <v-col cols="12" md="4"
              ><DatePickerField
                :label="deliveryDateLabel"
                v-model="order.deliveryDate"
            /></v-col>
          </v-row>
        </v-fade-transition>
      </v-card-text>
    </v-card>

    <v-card dense flat v-if="order.customer && order.product">
      <v-card-title>
        <v-icon left v-text="'mdi-car'" />
        <span v-text="$t('form.order.vehicleInformation')" />
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="6"
            ><DatePickerField
              :label="firstRegistrationDateLabel"
              v-model="order.firstRegistrationDate"
          /></v-col>

          <v-col cols="12" md="6">
            <TextField v-model="order.manufacturer" :label="manufacturerLabel"
          /></v-col>

          <v-col cols="12" md="6">
            <TextField v-model="order.model" :label="modelLabel"
          /></v-col>

          <v-col cols="12" md="6">
            <TextField v-model="order.licensePlate" :label="licensePlateLabel"
          /></v-col>

          <v-col cols="12" md="6">
            <VinScannerField v-model="order.vin" :label="vinLabel" />
          </v-col>

          <v-col cols="12" md="3">
            <TextField v-model="order.hsn" :label="hsnLabel" counter="4"
          /></v-col>

          <v-col cols="12" md="3">
            <TextField v-model="order.tsn" :label="tsnLabel" counter="5"
          /></v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import FormMixin from '@/mixins/form'
import Address from '@/models/address'
import VinScannerField from '@/components/Ui/VinScannerField'
import CustomerService from '@/services/customer'
import AddressService from '@/services/address'
import AddressPicker from '@/components/Ui/AddressPicker'

export default {
  name: 'form-order',
  mixins: [FormMixin],
  components: {
    VinScannerField,
    AddressPicker,
  },
  data: () => ({
    order: {
      vin: null,
      hsn: null,
      tsn: null,
      model: null,
      product: null,
      dueDate: null,
      customer: null,
      desiredDate: null,
      manufacturer: null,
      licensePlate: null,
      deliveryDate: null,
      damageNumber: null,
      contractNumber: null,
      inventoryNumber: null,
      firstRegistrationDate: null,

      inspectionLocation: new Address(),
      reportAddress: new Address(),
      invoiceAddress: new Address(),
    },
    products: [],
    customers: [],
    loading: false,
    productsLoading: false,
    customersLoading: false,
  }),
  async created() {
    await this.fetchCustomers()
  },
  methods: {
    fetchAddresses() {
      AddressService.query({
        customer: this.order.customer,
        product: this.order.product,
        type: 'invoice'
      })
        .then(response => {
          if (response.data.length > 0) {
            this.order.invoiceAddress = new Address(response.data[0])
          }
        })
        .catch(error => console.log('could not fetch invoice addresses'))

      AddressService.query({
        customer: this.order.customer,
        product: this.order.product,
        type: 'report'
      })
        .then(response => {
          if (response.data.length > 0) {
            this.order.reportAddress = new Address(response.data[0])
          }
        })
        .catch(error => console.log('could not fetch report addresses'))
    },

    async fetchCustomers() {
      this.customersLoading = true
      CustomerService.query({ activeproducts: true })
        .finally(() => this.customersLoading = false)
        .then(response => this.customers = response.data)
    },
    async fetchProducts(customerId) {
      this.productsLoading = true
      this.order.product = null
      CustomerService.getCustomerProducts(customerId, { params: { active: 1 } })
        .finally(() => this.productsLoading = false)
        .then(response => this.products = response.data)
    }
  },
  computed: {
    invoiceAddressName() {
      return this.order.invoiceAddress?.name || '-'
    },
    reportAddressName() {
      return this.order.reportAddress?.name || '-'
    },
    inspectionAddressName() {
      return this.order.inspectionLocation?.name || '-'
    },
    customerLabel() {
      return this.$t('form.order.customerLabel')
    },
    productLabel() {
      return this.$t('form.order.productLabel')
    },
    damageNumberLabel() {
      return this.$t('form.order.damageNumberLabel')
    },
    inventoryNumberLabel() {
      return this.$t('form.order.inventoryNumberLabel')
    },
    inspectionLocationsLabel() {
      return this.$t('form.order.inspectionLocationsLabel')
    },
    reportAddressLabel() {
      return this.$t('form.order.reportAddressLabel')
    },
    invoiceAddressLabel() {
      return this.$t('form.order.invoiceAddressLabel')
    },
    contractNumberLabel() {
      return this.$t('form.order.contractNumberLabel')
    },
    desiredDateLabel() {
      return this.$t('form.order.desiredDateLabel')
    },
    dueDateLabel() {
      return this.$t('form.order.dueDateLabel')
    },
    deliveryDateLabel() {
      return this.$t('form.order.deliveryDateLabel')
    },
    firstRegistrationDateLabel() {
      return this.$t('form.order.firstRegistrationDateLabel')
    },
    manufacturerLabel() {
      return this.$t('form.order.manufacturerLabel')
    },
    modelLabel() {
      return this.$t('form.order.modelLabel')
    },
    licensePlateLabel() {
      return this.$t('form.order.licensePlateLabel')
    },
    vinLabel() {
      return this.$t('form.order.vinLabel')
    },
    hsnLabel() {
      return this.$t('form.order.hsnLabel')
    },
    tsnLabel() {
      return this.$t('form.order.tsnLabel')
    },
  }
}
</script>
