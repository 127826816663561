import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import '@mdi/font/css/materialdesignicons.css'

import de from 'vuetify/es5/locale/de'
import es from 'vuetify/es5/locale/es'
import en from 'vuetify/es5/locale/en'
import nl from 'vuetify/es5/locale/nl'
import tr from 'vuetify/es5/locale/tr'
import fr from 'vuetify/es5/locale/fr'

// import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

const opts = {
  lang: {
    locales: { de, en, es, nl, fr, tr },
    current: process.env.VUE_APP_I18N_LOCALE || 'en'
  }
}

export default new Vuetify(opts)
