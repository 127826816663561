<template>
  <v-container class="vin-scanner ma-0 pa-0 fill-height" fluid>
    <v-row no-gutters>
      <v-col cols="12" md="10">
        <WebCam
          width="100%"
          height="100%"
          @cameras="setCameras"
          :deviceId="deviceId"
          ref="camera"
          class="cam"
        />
      </v-col>
      <v-col class="controls" cols="12" md="2">
        <Button
          icon
          x-large
          @click="switchCamera"
          class="switch-btn"
          v-if="cameras.length > 1"
        >
          <v-icon size="32" color="#FFF">mdi-camera-rear-variant</v-icon>
        </Button>
        <Button icon x-large @click="$emit('close')" class="close-btn">
          <v-icon size="32" color="#FFF">mdi-close-box-outline</v-icon>
        </Button>
        <Button
          fab
          x-large
          :loading="loading"
          class="shutter-btn ml-2"
          v-if="selectedCamera"
          @click="capture"
        >
          <v-icon size="64" color="red">mdi-camera-iris</v-icon>
        </Button>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import VinService from '@/services/vin'
import { WebCam } from "vue-web-cam"

export default {
  name: 'vin-scanner',
  components: { WebCam },
  data: () => ({
    selectedCamera: null,
    cameras: [],
    loading: false,
    currCamera: 0
  }),
  mounted() {
    // console.log("[VinScanner] Found cameras", this.cameras, this.selectedCamera)
  },
  methods: {
    capture() {
      let image = this.$refs.camera.capture()
      this.loading = true
      VinService.identVin(image)
        .finally(() => this.loading = false)
        .then(response => this.$emit('vin', response.data.vin))
        .catch(error => this.showError(this.$t(error.response.data.error)))
    },
    switchCamera() {
      this.currCamera += 1
      this.selectedCamera = this.cameras[this.currCamera % this.cameras.length]
    },
    setCameras(cameras) {
      this.cameras = cameras

      // enumerate devices and prefer the "environment camera"
      let prefered = this.cameras.filter(e => {
        if (!e.__proto__.hasOwnProperty('getCapabilities')) {
          // getCapabilities not supported. Trying the alternative way over the label text...
          let isBackCamera = /.*(back|rear).*/i.test(e.label)
          return isBackCamera
        }

        let cap = e.getCapabilities()
        return cap.facingMode.includes('environment')
      })

      this.selectedCamera = prefered.length > 0 ? prefered[0] : this.cameras[this.currCamera % this.cameras.length]
    },
  },
  computed: {
    deviceId() {
      return this.selectedCamera ? this.selectedCamera.deviceId : null
    },
    currentDeviceLabel() {
      return this.$t('camera.currentDevice')
    }
  }
}
</script>

<style lang="scss" scoped>
.vin-scanner {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #000;
  overflow: hidden;

  .cam {
    //position: relative;
    object-fit: cover;
    //top: 0;
    //bottom: 0;
    //left: 0;
    // position: absolute;
    //height: 100%;
    // width: 1;
  }

  .controls {
    //   position: relative;
    background-color: #000;
  }

  .shutter-btn {
    position: absolute;
    right: 40px;
    bottom: 50px;
  }

  .close-btn {
    position: absolute;
    right: 40px;
    top: 20px;
  }

  .switch-btn {
    position: absolute;
    right: 40px;
    top: 70px;
  }
}
</style>