
import OrderSidebar from '@/components/Order/Navigation.vue'

const meta = {
  sidebar: OrderSidebar
}

export default {
  path: 'order/:orderId',
  component: () => import(/* webpackChunkName: "order" */ '@/views/Order/index.vue'),

  children: [{
    path: 'data',
    name: 'order.data',
    component: () => import(/* webpackChunkName: "order" */ '@/views/Order/Data.vue'),
    props: true,
    meta
  }, {
    path: 'dispose',
    name: 'order.dispose',
    component: () => import(/* webpackChunkName: "order" */ '@/views/Order/Dispose.vue'),
    props: true,
    meta
  }, {
    path: 'documents',
    name: 'order.documents',
    component: () => import(/* webpackChunkName: "order" */ '@/views/Order/Documents.vue'),
    props: true,
    meta
  }, {
    path: 'comments',
    name: 'order.comments',
    component: () => import(/* webpackChunkName: "order" */ '@/views/Order/Comments/index.vue'),
    props: true,
    meta
  }, {
    path: 'history',
    name: 'order.history',
    component: () => import(/* webpackChunkName: "order" */ '@/views/Order/History/index.vue'),
    props: true,
    meta
  }]
}