<template>
  <v-menu left bottom>
    <template v-slot:activator="{ on, attrs }">
      <Button icon v-bind="attrs" v-on="on">
        <v-icon v-text="'mdi-account'" />
      </Button>
    </template>

    <v-list v-if="isAuthenticated">
      <v-list-item @click="logout">
        <v-list-item-icon>
          <v-icon left v-text="'mdi-logout-variant'" />
        </v-list-item-icon>
        <v-list-item-title v-text="$t('app.logout')" />
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex"
import AuthApiService from '@/services/auth'
import { LOGOUT } from "@/store/actions.type"

export default {
  name: 'app-user-menu',
  methods: {
    logout() {
      AuthApiService.logout()
        .finally(() => this.$store.dispatch(LOGOUT))
        .finally(() => this.$router.push({ name: "login" })
          .then(() => this.showSuccess(this.$root.$t('message.logoutSuccess')))
          .catch(error => console.log(error)))
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated'])
  }
}
</script>
