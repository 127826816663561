export default {
  createOrder: {
    title: 'Create Order',
    subtitle: 'Please select a customer and product.',
    newOrderTabLabel: 'New order',
    multiImportTabLabel: 'Mass import orders',
    createOrderLabel: 'Create order'
  },

  multiImport: {
    title: 'Mass import',
    subtitle:'',
    importOrdersLabel: 'Import',
  },

  address: {
    title: 'Address',
    apply: 'Apply',
  },

  comment: {
    createCommentLabel: 'Create comment'
  },

  closeDialogLabel: 'Close'
}