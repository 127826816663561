import Vue from 'vue'

Vue.component('ViewContainer', require('@/components/ViewContainer').default)

Vue.component('Card', require('@/components/Ui/Card').default)
Vue.component('Table', require('@/components/Ui/Table').default)
Vue.component('Chip', require('@/components/Ui/Chip').default)
Vue.component('Button', require('@/components/Ui/Button').default)
Vue.component('TooltipButton', require('@/components/Ui/TooltipButton').default)
// Vue.component('Img', require('@/components/Ui/Img').default)

Vue.component('SelectField', require('@/components/Ui/SelectField').default)
Vue.component('FileInput', require('@/components/Ui/FileInput').default)
Vue.component('TextField', require('@/components/Ui/TextField').default)
Vue.component('Autocomplete', require('@/components/Ui/Autocomplete').default)
Vue.component('DatePickerField', require('@/components/Ui/DatePickerField').default)
Vue.component('SwitchBtn', require('@/components/Ui/SwitchBtn').default)
Vue.component('TextArea', require('@/components/Ui/TextArea').default)

Vue.component('ErrorAlert', require('@/components/ErrorAlert').default)
Vue.component('OrderPage', require('@/components/Order/Page').default)
