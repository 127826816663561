var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Table',{attrs:{"headers":_vm.header,"items":_vm.reports,"search":_vm.search,"loading":_vm.loading,"options":_vm.options},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.from",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTimeStr24(item.from))+" ")]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTimeStr24(item.to))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"color":"brown darken-3"},on:{"click":function($event){return _vm.download(item)}}},on),[_vm._v("mdi-text-box-outline")])]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('table.invoiceReports.download'))}})])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }