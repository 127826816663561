import ApiService from './base'

export default {
  query(params?: any) {
    return ApiService.query('/api/orders', {
      params: params
    })
  },
  getOrders(params?: any) {
    return ApiService.get('/api/orders', params)
  },
  getOrder(orderId: number) {
    return ApiService.get(`/api/orders/${orderId}`)
  },
  update(orderId: number, data: Object) {
    // @ts-ignore
    data._method = 'PATCH'
    return ApiService.post(`/api/orders/${orderId}`, data)
  },
  revokeOrder(orderId: number) {
    return ApiService.post(`/api/orders/${orderId}/revoke`, {})
  },
  disposeOrder(orderId: number, data) {
    return ApiService.post(`/api/orders/${orderId}/dispose`, data)
  },
  createOrder(orderData: Object, config: any) {
    return ApiService.post(`/api/orders`, orderData, config)

  },
  massImport(data: { customer: Number, delimiter: String, file: File }) {
    let formData = new FormData()
    formData.append('customerId', data.customer.toString())
    formData.append('delimiter', data.delimiter.valueOf())
    formData.append('importfile', data.file, data.file.name)
    return ApiService.post(`api/orders/upload`, formData)
  },
  lockOrder(orderId: number) {
    return ApiService.post(`/api/orders/${orderId}/lock`, {})
  },
  unlockOrder(orderId: number) {
    return ApiService.post(`/api/orders/${orderId}/unlock`, {})
  },
  cancel(orderId: number) {
    return ApiService.post(`/api/orders/${orderId}/status`, {
      status: 'cancel'
    })
  }
}