<template>
  <v-list color="primary" class="darken-2" dark dense nav>
    <v-subheader class="title">
      {{ $t("sidebar.inboxes") }}
      <v-spacer />
      <v-icon
        v-text="`mdi-chevron-${open ? 'up' : 'down'}`"
        @click="open = !open"
      />
    </v-subheader>
    <v-slide-y-transition>
      <v-list-item-group v-model="inbox" v-show="open">
        <v-list-item
          v-for="(item, i) in inboxes"
          :key="`inbox-${i}`"
          :to="inboxRoute(item)"
          exact
        >
          <v-list-item-icon v-if="getIcon(item.id)">
            <v-icon v-text="getIcon(item.id)" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="$t(item.name)" />
          </v-list-item-content>
          <v-list-item-action>
            <Chip
              v-text="item.count"
              color="success"
              class="darken-1"
              x-small
            />
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-slide-y-transition>
  </v-list>
</template>

<script>
import InboxIcons from '@/configs/inbox-icons'

export default {
  name: 'inbox-list',
  props: {
    inboxes: {
      type: Array,
      default: () => []
    }
  },
  data: () => ({
    inbox: null,
    open: true
  }),
  methods: {
    getIcon(icon) {
      return InboxIcons[icon]
    },
    inboxRoute(inbox) {
      return { name: 'inbox', params: { inboxId: inbox.id, filters: inbox.filters } }
    }
  },
}
</script>