<template>
  <v-form ref="form" :errors="errors" v-model="valid">
    <v-scroll-y-transition>
      <ErrorAlert class="mt-2" v-if="errors" :errors="errors.errors" />
    </v-scroll-y-transition>
    <v-card flat dense>
      <v-card-title>
        <v-icon left v-text="'mdi-account'" />
        <span class="subtitle-1" v-text="$t('form.address.addressInfoTitle')" />
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" v-if="addressType && customer">
            <AddressSelector
              v-if="locations.length > 0"
              v-model="selectedLocation"
              :items="locations"
              :loading="locationsLoading"
              @change="setSelectedAddress"
            />
          </v-col>

          <v-col cols="12" md="6">
            <SelectField
              :items="titles"
              item-text="label"
              item-value="label"
              v-model="address.title"
              :label="titleLabel"
            />
          </v-col>

          <v-col cols="12" md="6">
            <TextField v-model="address.name" :label="nameLabel" />
          </v-col>

          <v-col cols="12" md="6">
            <TextField v-model="address.firstname" :label="firstNameLabel" />
          </v-col>

          <v-col cols="12" md="6">
            <TextField v-model="address.lastname" :label="lastNameLabel" />
          </v-col>

          <v-col cols="12" md="4">
            <TextField v-model="address.street" :label="streetNameLabel" />
          </v-col>

          <v-col cols="12" md="4">
            <TextField v-model="address.streetNumber" :label="streetNrLabel" />
          </v-col>

          <v-col cols="12" md="4">
            <TextField v-model="address.zipcode" :label="zipLabel" />
          </v-col>

          <v-col cols="12" md="4">
            <TextField v-model="address.city" :label="cityLabel" />
          </v-col>

          <v-col cols="12" md="4">
            <TextField v-model="address.state" :label="stateLabel" />
          </v-col>

          <v-col cols="12" md="4">
            <TextField v-model="address.country" :label="countryLabel" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat dense>
      <v-card-title>
        <v-icon left v-text="'mdi-account-tie-voice'" />
        <span class="subtitle-1" v-text="$t('form.address.contactInfoTitle')" />
      </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="6">
            <TextField v-model="address.phone" :label="phoneLabel" />
          </v-col>

          <v-col cols="12" md="6">
            <TextField v-model="address.mobile" :label="mobileLabel" />
          </v-col>

          <v-col cols="12" md="6">
            <TextField v-model="address.fax" :label="faxLabel" />
          </v-col>

          <v-col cols="12" md="6">
            <TextField v-model="address.email" :label="mailLabel" />
          </v-col>

          <v-col cols="12">
            <TextArea v-model="address.note" :label="noteLabel" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import Address from '@/models/address'
import FormMixin from '@/mixins/form'
import AddressService from '@/services/address'
import AddressSelector from './AddressSelector'

export default {
  name: 'form-address',
  mixins: [FormMixin],
  components: { AddressSelector },
  props: {
    addressType: {
      type: String,
    },
    customer: {
      type: Number,
    }
  },
  data: () => ({
    locations: [],
    locationsLoading: false,
    selectedLocation: null,

    address: new Address(),
  }),
  mounted() {
    this.address = this.initial ? { ...this.initial } : this.address

    this.fetchAddresses()
  },
  methods: {
    setSelectedAddress(address) {
      this.address = address
    },
    fetchAddresses() {
      if (this.customer && this.addressType) {
        AddressService.query({
          customer: this.customer,
          type: this.addressType
        })
          .then(response => this.locations = response.data)
          .catch(() => { })
      }
    },
  },
  computed: {
    titles() {
      return [
        { label: this.$t('form.address.title.male') },
        { label: this.$t('form.address.title.female') },
        { label: this.$t('form.address.title.company') },
        { label: this.$t('form.address.title.doctor') },
      ]
    },
    titleLabel() {
      return this.$t('form.address.titleLabel')
    },
    nameLabel() {
      return this.$t('form.address.nameLabel')
    },
    firstNameLabel() {
      return this.$t('form.address.firstNameLabel')
    },
    lastNameLabel() {
      return this.$t('form.address.lastNameLabel')
    },
    streetNameLabel() {
      return this.$t('form.address.streetNameLabel')
    },
    streetNrLabel() {
      return this.$t('form.address.streetNrLabel')
    },
    zipLabel() {
      return this.$t('form.address.zipLabel')
    },
    cityLabel() {
      return this.$t('form.address.cityLabel')
    },
    stateLabel() {
      return this.$t('form.address.stateLabel')
    },
    countryLabel() {
      return this.$t('form.address.countryLabel')
    },
    phoneLabel() {
      return this.$t('form.address.phoneLabel')
    },
    mobileLabel() {
      return this.$t('form.address.mobileLabel')
    },
    faxLabel() {
      return this.$t('form.address.faxLabel')
    },
    mailLabel() {
      return this.$t('form.address.mailLabel')
    },
    noteLabel() {
      return this.$t('form.address.noteLabel')
    },
  },
  watch: {
    initial: {
      deep: true,
      handler() {
        this.address = this.initial ? { ...this.initial } : this.address
        this.fetchAddresses()
      }
    },
    customer() {
      this.fetchAddresses()
    },
    addressType() {
      this.fetchAddresses()
    }
  }
}
</script>