<template>
  <TextField v-model="vin" v-bind="$attrs" v-on="$listeners" counter="17">
    <template #append>
      <TooltipButton
        plain
        :tooltip="$t('form.order.scanVinTooltip')"
        @click="dialog = true"
        icon
        small
      >
        <v-icon v-text="'mdi-camera'" />
      </TooltipButton>

      <v-dialog fullscreen v-model="dialog">
        <VinScanner v-if="dialog" @close="dialog = false" @vin="setVin" />
      </v-dialog>
    </template>
  </TextField>
</template>

<script>
import VinScanner from '@/components/VinScanner'

export default {
  name: 'vin-scanner-field',
  props: ['value'],
  components: { VinScanner },
  data: () => ({
    vin: null,
    dialog: false,
  }),
  created() {
    this.vin = this.value
  },
  methods: {
    setVin(vin) {
      this.vin = vin
      this.showSuccess(this.$t('message.setVinSuccess'))
      this.dialog = false
    },
  },
  watch: {
    value(val) {
      this.vin = val
    },
    vin(val) {
      this.$emit('input', this.vin)
    }
  }
}
</script>