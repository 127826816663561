import app from './app'
import form from './form'
import page from './page'
import message from './message'
import table from './table'
import sidebar from './sidebar'
import order from './order'
import dialog from './dialog'
import inbox from './inbox'

export default {
  app,
  form,
  page,
  message,
  table,
  sidebar,
  order,
  dialog,
  inbox,

  month: {
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
  }
}