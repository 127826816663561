<template>
  <v-card dense>
    <v-card-title>
      <v-icon left v-text="getIcon(inbox.id)" v-if="getIcon(inbox.id)" />
      {{ $t(inbox.name) }}
      <Chip v-text="inbox.count" color="success" class="darken-1 ml-2" small />
      <v-spacer />
      <Button
        :to="inboxRoute(inbox)"
        v-text="$t('page.dashboard.openInboxButton')"
        text
      />
    </v-card-title>
    <!--<v-card-text>
      <v-sparkline
        :fill="fill"
        :line-width="width"
        :padding="padding"
        :smooth="radius || false"
        :value="value"
        auto-draw
      />
    </v-card-text>
    <v-card-actions>
       <v-spacer />
      <Button
        :to="inboxRoute(inbox)"
        v-text="$t('page.dashboard.openInboxButton')"
        text
      />
    </v-card-actions>-->
  </v-card>
</template>

<script>
import InboxIcons from '@/configs/inbox-icons'

export default {
  name: 'dashboard-inbox-card',
  props: {
    inbox: {
      type: Object,
    }
  },
  data: () => ({
    fill: true,
    gradient: ['#ccc'],
    padding: 0,
    radius: 100,
    value: [],
    width: 3,
  }),
  mounted() {
    let r = Math.random() * (25 - 1) + 1
    for (let i = 0; i < r; i++) {
      let r2 = Math.random() * (100 - 1) + 20
      this.value.push(r2)
    }
  },
  methods: {
    getIcon(icon) {
      return InboxIcons[icon]
    },
    inboxRoute(inbox) {
      return { name: 'inbox', params: { inboxId: inbox.id, filters: inbox.filters } }
    },
  }
}
</script>