<template>
  <ViewContainer>
    <v-card>
      <v-card-title v-text="$t('page.dashboard.title')" />
      <v-card-subtitle v-text="$t('page.dashboard.subtitle')" />
      <v-card-text>
          <v-container>
            <v-row>
              <v-col
                v-for="inbox in inboxes"
                :key="`dashboard-inbox-${inbox.id}`"
                cols="12"
                md="6"
              >
                <Inbox :inbox="inbox" />
              </v-col>
            </v-row>
          </v-container>
      </v-card-text>
    </v-card>
  </ViewContainer>
</template>

<script>
import InboxService from '@/services/inbox'
import Inbox from './Inbox'

export default {
  name: 'page-dashboard',
  components: { Inbox },
  data: () => ({
    inboxes: [],
  }),
  mounted() {
    this.fetchInboxes()
  },
  methods: {
    fetchInboxes() {
      return InboxService.getInboxes()
        .then(response => this.inboxes = response.data)
        .catch(error => console.log(error))
    }
  }
}
</script>