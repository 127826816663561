<template>
  <v-dialog max-width="800" v-bind="$attrs" v-on="$listeners">
    <template v-slot:default="dialog">
      <v-tabs background-color="primary darken-2" v-model="tab" dark>
        <v-tabs-slider />

        <v-tab>
          <v-icon left>mdi-plus-box</v-icon>
          {{ $t("dialog.createOrder.newOrderTabLabel") }}
        </v-tab>
        <v-tab>
          <v-icon left>mdi-plus-box-multiple</v-icon>
          {{ $t("dialog.createOrder.multiImportTabLabel") }}
        </v-tab>
        <v-tab-item>
          <Card>
            <v-card-title v-text="$t('dialog.createOrder.title')" />
            <v-card-subtitle v-text="$t('dialog.createOrder.subtitle')" />
            <v-card-text>
              <OrderForm ref="orderForm" />
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                @click="createOrder"
                color="success"
                v-text="$t('dialog.createOrder.createOrderLabel')"
              />
              <v-btn
                text
                @click="dialog.value = false"
                v-text="$t('dialog.closeDialogLabel')"
              />
            </v-card-actions>
          </Card>
        </v-tab-item>
        <v-tab-item>
          <Card>
            <v-card-title v-text="$t('dialog.multiImport.title')" />
            <v-card-subtitle v-text="$t('dialog.multiImport.subtitle')" />
            <v-card-text>
              <OrderMassImportForm ref="massImportForm" />
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                @click="massImportOrders"
                color="success"
                v-text="$t('dialog.multiImport.importOrdersLabel')"
              />
              <v-btn
                text
                @click="dialog.value = false"
                v-text="$t('dialog.closeDialogLabel')"
              />
            </v-card-actions>
          </Card>
        </v-tab-item>
      </v-tabs> </template
  ></v-dialog>
</template>

<script>
import OrderForm from '@/components/Forms/CreateOrder'
import OrderMassImportForm from '@/components/Forms/OrderMassImport'
import OrderService from '@/services/order'

export default {
  name: 'dialog-order-create',
  components: {
    OrderForm,
    OrderMassImportForm
  },
  data: () => ({
    tab: 0,
  }),
  methods: {
    massImportOrders() {
      if (this.$refs.massImportForm.validate()) {
        const orderData = this.$refs.massImportForm.order
        OrderService.massImport(orderData)
          .then(response => this.$emit('created', response.data))
          .then(() => this.showSuccess(this.$t('message.massImportSuccess')))
          .then(() => this.$refs.massImportForm.reset())
          .then(() => this.$refs.massImportForm.fetchCustomers())
      }
    },
    createOrder() {
      if (this.$refs.orderForm.validate()) {
        const orderData = this.$refs.orderForm.order
        let config = {
          headers: {
            lang: this.$i18n.locale,
          }
        }
        OrderService.createOrder(orderData, config)
          .then(response => this.$emit('created', response.data))
          .then(() => this.showSuccess(this.$t('message.orderCreateSuccess')))
          .then(() => this.$refs.orderForm.reset())
          .then(() => this.$refs.orderForm.fetchCustomers())
      }
    },
  },
}
</script>