<template>
  <v-data-table v-bind="$attrs" v-on="$listeners" :footer-props="footerProps">
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
      <slot :name="slot" v-bind="scope" />
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    footerProps: {
      type: Object,
      default: () => ({
        itemsPerPageOptions: [10, 25, 100, -1]
      })
    }
  }
}
</script>
