import ApiService from './base'

export default {
  query(params?: any) {
    return ApiService.query(`/api/customers`, {
      params: params
    })
  },
  getCustomer(customerId: number) {
    return ApiService.query(`/api/customers/${customerId}`)
  },
  getCustomerProducts(customerId: number, params = {}) {
    return ApiService.query(`/api/customers/${customerId}/products`, params)
  }
}
