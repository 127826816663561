<template>
  <Card :loading="loading">
    <v-toolbar flat dense>
      <v-toolbar-title v-if="title" v-text="title" />

      <v-spacer />

      <slot name="actions"> </slot>

      <TooltipButton
        icon
        plain
        v-if="saveBtn && canEdit"
        :loading="saving"
        @click="$emit('save')"
        :tooltip="$t('order.page.saveTooltip')"
      >
        <v-hover v-slot="{ hover }">
          <v-icon :color="hover ? 'green darken-2' : 'green'"
            >mdi-content-save</v-icon
          >
        </v-hover>
      </TooltipButton>
      <TooltipButton
        icon
        plain
        color="secondary"
        v-if="refreshBtn"
        @click="$emit('refresh')"
        :tooltip="$t('order.page.refreshTooltip')"
      >
        <v-hover v-slot="{ hover }">
          <v-icon :color="hover ? 'orange darken-2' : 'orange'"
            >mdi-refresh</v-icon
          >
        </v-hover>
      </TooltipButton>

      <TooltipButton
        icon
        plain
        color="error"
        v-if="init && ((orderStatus < 300 && canDelete) || isAdmin)"
        @click="cancelOrder"
        :tooltip="$t('order.page.cancelTooltip')"
      >
        <v-hover v-slot="{ hover }">
          <v-icon :color="hover ? 'red darken-2' : 'red'">mdi-recycle</v-icon>
        </v-hover>
      </TooltipButton>

      <template #extension v-if="$slots.extra">
        <slot name="extra"></slot>
      </template>
    </v-toolbar>
    <v-divider />
    <v-card-text>
      <slot />
    </v-card-text>
  </Card>
</template>

<script>
import { mapGetters } from "vuex";
import Mainbus from "@/mainbus";
import OrderService from "@/services/order";

export default {
  name: "order-page",
  props: {
    title: {
      type: String,
      default: "",
    },
    saveBtn: {
      type: Boolean,
      default: true,
    },
    cancelBtn: {
      type: Boolean,
      default: false,
    },
    refreshBtn: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    saving: {
      type: Boolean,
      default: false,
    },
    orderStatus: {
      type: Number,
      default: 999,
    },
  },
  data: () => ({
    init: false,
  }),
  methods: {
    closeOrder() {
      Mainbus.$emit("closeOrder", this.$route.params.orderId);
    },
    cancelOrder() {
      OrderService.cancel(this.$route.params.orderId)
        .then(() => this.showSuccess(this.$t("message.cancelOrderSuccess")))
        .catch(() => this.showError(this.$t("message.cancelOrderFailure")));
    },
  },
  computed: {
    ...mapGetters(["isAdmin", "canEdit", "canDelete"]),
  },
  watch: {
    orderStatus() {
      this.init = true;
    },
  },
};
</script>
