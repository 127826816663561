<template>
  <v-app-bar app>
    <v-app-bar-nav-icon @click="$emit('toggleSidebar')" />

    <SearchBar class="mr-3" />

    <v-spacer></v-spacer>

    <UserMenu />
    <LanguageMenu />
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";

import UserMenu from "./UserMenu";
import SearchBar from "./SearchBar";
import LanguageMenu from "./LanguageMenu";

export default {
  name: "appbar",
  components: {
    UserMenu,
    SearchBar,
    LanguageMenu,
  },
  computed: {
    ...mapGetters(["isAuthenticated", "isAdmin", "canEdit"]),
  },
};
</script>
