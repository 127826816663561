export default {
  de: 'Deutsch',
  da: 'Dansk',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  hr: 'Hrvatski',
  it: 'Italiano',
  nl: 'Nederlands',
  pl: 'Polski',
  pt: 'Português',
  ru: 'Pусский',
  sv: 'Svenska',
  tr: 'Türkçe',
  fi: 'Suomi',
  no: 'Norsk',
  nl_be: 'Nederlands (BE)'
}
