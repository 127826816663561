import ApiService from './base'

export default {
  fetchReports(userId: number, params?: any): Promise<any> {
    return ApiService.query(`api/dynamicreports/${userId}/reports`, {
      params: params
    })
  },

  downloadReport(reportId: number, params){
    return ApiService.download(`api/dynamicreports/${reportId}/download`, params);
  }
}