<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on }">
      <Button v-bind="$attrs" v-on="{ ...$listeners, ...on }">
        <slot></slot>
      </Button>
    </template>
    {{ tooltip }}
  </v-tooltip>
</template>

<script>
export default {
  props: {
    tooltip: {
      type: String,
      default: ''
    }
  }
}
</script>