export default {
  addressPickerTooltip: 'Set an address',

  login: {
    emailLabel: 'E-Mail',
    passwordLabel: 'Password',
    loginButton: 'Login'
  },

  order: {
    customerLabel: 'Customer',
    productLabel: 'Product',
    contractNumberLabel: 'Contract number',
    claimNumberLabel: 'Claim number',
    inventoryNumberLabel: 'Inventory number',
    inspectionLocationsLabel: 'Inspection location',
    reportAddressLabel: 'Report Address',
    invoiceAddressLabel: 'Invoice Address',
    desiredDateLabel: 'Desired date',
    dueDateLabel: 'Due date',
    deliveryDateLabel: 'Delivery Date',
    firstRegistrationDateLabel: 'First registration date',
    manufacturerLabel: 'Manufacturer',
    modelLabel: 'Model',
    licensePlateLabel: 'License Plate',
    vinLabel: 'Vin',
    hsnLabel: 'Hsn',
    tsnLabel: 'Tsn',
    mileageLabel: 'Mileage',
    registrationDateLabel: 'Registration date',
    damageDateLabel: 'Damage date',
    damageAreaLabel: 'Damaged area',
    oldDamageLabel: 'Old damages',
    orderInfoLabel: 'Order Information',
    createNewAddressLabel: 'Add new Address',
    productNameLabel: 'Product',
    customerGroupLabel: 'Customer group',
    responsiblePersonLabel: 'Responsible person',
    lawyerAssignedLabel: 'Lawyer assigned',
    referenceNumberLabel: 'Reference number',
    arrivalDateLabel: 'Arrival date',
    ordererLabel: 'Orderer',
    damageNumberLabel: 'Damage number',
    designatedDateLabel: 'Designated date',
    byOrderOfLabel: 'By order of',
    importFileLabel: 'Import file',
    orderInformation: 'Order information',
    vehicleInformation: 'Vehicle information',
    scanVinTooltip: 'Open camera to scan vin',
    delimiterLabel: 'Delimiter',
  },

  address: {
    titleLabel: 'Title',
    nameLabel: 'Name',
    firstNameLabel: 'First name',
    lastNameLabel: 'Last name',
    streetNameLabel: 'Street',
    streetNrLabel: 'House number',
    zipLabel: 'Zip code',
    cityLabel: 'City',
    stateLabel: 'State',
    countryLabel: 'Country',
    phoneLabel: 'Phone number',
    mobileLabel: 'Mobile number',
    faxLabel: 'Fax number',
    mailLabel: 'E-Mail',
    noteLabel: 'Note',
    locationsLabel: 'Saved locations',
    locationsHint: 'Selecting a saved location will load it\'s address in the fields below.',
    addressInfoTitle: 'Address information',
    contactInfoTitle: 'Contact information',
    title: {
      female: 'Mrs.',
      male: 'Mr.',
      company: 'Company',
      doctor: 'Dr.',
    },
  },

  dispose: {
    designatedLocationLabel: 'Designated location',
    designatedExpertLabel: 'Designated expert',
    designatedDateLabel: 'Designated date',
    inspectionLocationLabel: 'Inspection location',
  },

  comment: {
    internalLabel: 'Internal',
    commentLabel: 'Comment'
  },

  upload: {
    uploadFieldLabel: 'Choose file',
    fileTypeLabel: 'Choose type',
    uploadFileButton: 'Upload',
    downloadZipFileButton: 'Download ZIP',
    type: {
      schaden: 'Damage',
      beweissicherung: 'Evidence',
      pflichtbild: 'Mandatory',
      sonstige: 'Other',
      kostenvoranschlag: 'Cost estimate',
      signatur: 'Signature',
      gutachten: 'Report',
      interne_files: 'Internal files',
      technik_bilder: 'Technical Images',
      pflicht_original: 'Mandatory originals',
      rechnung_kunde: 'Invoice customer',
      abtretungserklaerung: 'Declaration of assignment',
      documents: 'Document',
      old_damages: 'Old damage',
      dynamic_task: 'Dynamic Task',
      email_attachment: 'E-Mail Attachment',
    }
  },

  validator: {
    required: 'This field is required'
  },

  error:{
    terminatedDatetime:{
      required:'Terminationdate is required'
    }
  },

  datePicker: {
    dateOk: 'Choose',
    dateCancel: 'Close'
  }
}
