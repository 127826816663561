export default {
  id: 'OrderID',

  page: {
    saveTooltip: 'Save order',
    refreshTooltip: 'Refresh data',
    closeTooltip: 'Close order',
    cancelTooltip:'Cancel order'
  },

  data: {
    title: 'Order data',
    subtitle: '',
    inspectionLocation: 'Inspection address',
    expertLocation: 'Expert address',
    billingAddress: 'Billing address',
    reportAddress: 'Report address',
    vehicleData: 'Vehicle data',
    hsnLabel: 'Hsn',
    tsnLabel: 'Hsn',
    lawyer: {
      none: '-'
    }
  },

  vehicleData: {
    title: 'Vehicle data',
  },

  dispose: {
    title: 'Dispose order',
    subtitle: '',
    revokeOrderTooltip: 'Revoke order',
    revokeOrderConfirm: 'Are you sure you want to revoke this Order?',
    expertUser: 'User',
    expertLocation: 'Location',
    expertDistance: 'Distance',
    expertAddress: 'Address',
    noExpertsFound: 'No experts found!',
    disposeHistoryTitle: 'Related history entries'
  },

  documents: {
    title: 'Documents',
    subtitle: '',
    confirmDeleteFile: 'Are you sure you want to delete the selected File?',
    confirmChangeFileType: 'Are you sure to change the type of this file?'
  },

  history: {
    title: 'History',
    subtitle: '',
    noActivity: 'There is no activity for this order recorded yet.',

    message: {
      dispose: 'Order was disposed to {organisation}.'
    },

    type: {
      edit: 'Edit',
      upload: 'Upload',
      comment: 'Comment',
      cancel: 'Cancelation',
      finish: 'Finish',
      dispose: 'Disposition',
      revoke: 'Revokation',
      inspection: 'Inspection',
      export: 'Export',
      create: 'Creation',
      accept: 'Accept',
    }
  },

  comments: {
    create: {
      title: 'Create comment',
    },

    title: 'Comments',
    subtitle: '',
    noComments: 'This order doesn\'t have any comments yet',
    createCommentBtn: 'Create a new comment',
    internalComment: 'Internal Comment'
  },

  nav: {
    dataLabel: 'Order data',
    disposeLabel: 'Dispose',
    documentsLabel: 'Documents',
    commentsLabel: 'Comments',
    historyLabel: 'History',
  },

  address: {
    company: 'Company',
    firstName: 'First name',
    lastName: 'Last name',
    street: 'Street',
    streetNr: 'Street Nr.',
    zipCode: 'Zip code',
    city: 'City',
    state: 'State',
    phone: 'Phone number',
    cellPhone: 'Cellphone number',
    fax: 'Fax number',
    mail: 'E-Mail',
    note: 'Note',
    editTooltip: 'Edit this address',
    contactName: 'Contact name',
    address: 'Address'
  },
}
