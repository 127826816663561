export default {
  loginSuccess: 'Login successful, welcome!',
  logoutSuccess: 'Logout successful, goodbye!',
  loginFail: 'There was a problem to Login your user.',
  uploadFileSuccess: 'Upload successful!',
  uploadFileError: 'There was a problem uploading the Document!',
  deleteFileSuccess: 'The File was deleted successfully!',
  setAddressSuccess: 'Address changed',
  orderAlreadyOpen: 'This order is already open.',
  createCommentSuccess: 'Comment created successfully!',
  orderCreateSuccess: 'Order successfully created!',
  disposeOrderSuccess: 'Order has been successfully disposed!',
  orderLocked: 'The order is currently locked by another user',
  pageRefreshed: 'Refreshed',
  pageRefreshFailed: 'Refresh failed',
  orderUpdateSuccess: 'Order saved successfully!',
  languageChanged: 'Language changed successfully',
  revokeOrderFail: 'There was a problem revoking the order',
  revokeOrderSuccess: 'Order successfully revoked',
  changeFileTypeSuccess: 'File type changed successfully',
  setVinSuccess: 'Vin successfully parsed',
  cancelOrderSuccess: 'Order successfully canceled',
  cancelOrderFailure: 'Order could not be canceled.',
  massImportSuccess: 'Import file uploaded successfully!',
  downloadZipFileSuccess: 'Download successful!',
  downloadZipFileError: 'There was a problem downloading the Zip!'
}
