<template>
  <v-form ref="form" :errors="errors" v-model="valid">
    <v-scroll-y-transition>
      <ErrorAlert class="mt-2" v-if="errors" :errors="errors.errors" />
    </v-scroll-y-transition>
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <Autocomplete
            v-model="order.customer"
            :items="customers"
            :loading="customersLoading"
            :label="customerLabel"
            item-text="name"
            item-value="id"
            :rules="rules.required"
          />
        </v-col>

        <v-col cols="12">
          <FileInput
            v-model="order.file"
            :rules="rules.required"
            :label="importFileLabel"
            @change="onFileInput"
          />
        </v-col>
        <v-col cols="12" md="6">
          <SelectField
            :items="delimiters"
            item-text="delimiter"
            item-value="delimiter"
            v-model="order.delimiter"
            :label="delimiterLabel"
            :rules="rules.required"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import FormMixin from '@/mixins/form'
import CustomerService from '@/services/customer'

export default {
  name: 'form-order-mass-import',
  mixins: [FormMixin],
  data: () => ({
    order: {
      customer: null,
      file: null,
      delimiter: null
    },
    customers: [],
    delimiters: [',', ';'],
    customersLoading: false,
  }),
  async created() {
    await this.fetchCustomers()
  },
  methods: {
    onFileInput(file) {
      this.order.file = file
    },
    fetchCustomers() {
      this.customersLoading = true
      CustomerService.query({ activeproducs: true })
        .finally(() => this.customersLoading = false)
        .then(response => this.customers = response.data)
    },
  },
  computed: {
    customerLabel() {
      return this.$t('form.order.customerLabel')
    },
    importFileLabel() {
      return this.$t('form.order.importFileLabel')
    },
    delimiterLabel() {
      return this.$t('form.order.delimiterLabel')
    },
  }
}
</script>