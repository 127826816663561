<template>
  <v-dialog max-width="800" v-bind="$attrs" v-on="$listeners" ref="dialog">
    <template v-slot:default="dialog">
      <v-card>
        <v-card-title>
          <v-icon left v-text="'mdi-map-marker'" />
          {{ $t("dialog.address.title") }}
        </v-card-title>
        <v-card-text>
          <AddressForm
            :initial="address"
            :addressType="addressType"
            :customer="customer"
            ref="addressForm"
          />
        </v-card-text>
        <v-card-actions class="justify-end">
          <Button
            @click="update"
            color="success"
            v-text="$t('dialog.address.apply')"
          />
          <Button
            text
            @click="dialog.value = false"
            v-text="$t('dialog.closeDialogLabel')"
          />
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import AddressForm from '@/components/Forms/Address'

export default {
  name: 'dialog-address',
  components: {
    AddressForm
  },
  props: {
    address: {
      type: Object
    },
    addressType: {
      type: String,
    },
    customer: {
      type: Number,
    }
  },
  data: () => ({
  }),
  methods: {
    reset(){
      return this.$refs.addressForm.reset()
    },
    update() {
      if (this.$refs.addressForm.validate()) {
        const address = this.$refs.addressForm.address
        this.$emit('update', address)
        this.reset()
        this.showInfo(this.$t('message.setAddressSuccess'))
      }
    }
  }
}
</script>