import Vue from 'vue'

import { store } from './store'

import router from '@/router'
import i18n from '@/plugins/i18n'
import vuetify from '@/plugins/vuetify'

import ApiService from '@/services/base'
import { LangSwitcher } from '@huesgesone/idp-npm-packages'

import App from '@/App.vue'
import '@/sass/app.scss'

require('./plugins/mixins')
require('./plugins/components')

Vue.config.productionTip = false

ApiService.init()

new Vue({
  i18n,
  router,
  vuetify,
  store,
  render: h => h(App),
  created() {
    LangSwitcher.loadMessages(i18n)
  }
}).$mount('#app')
