<template>
  <v-btn v-bind="attrs" v-on="$listeners" :tile="tile" :depressed="depressed">
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  props: {
    tile: {
      type: Boolean,
      default: false
    },
    depressed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    attrs() {
      return {
        dense: this.isDesktop,
        ...this.$attrs
      }
    }
  }
}
</script>