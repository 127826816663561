<template>
  <TextField v-bind="$attrs" v-on="$listeners" readonly :value="address.name">
    <template v-slot:append>
      <TooltipButton
        :tooltip="$t('form.addressPickerTooltip')"
        @click="dialog = true"
        icon
        plain
        small
        ><v-icon v-text="'mdi-map-marker-plus-outline'"
      /></TooltipButton>
      <AddressDialog
        :customer="customerId"
        :address="address"
        :addressType="type"
        v-model="dialog"
        @update="setAddress"
      />
    </template>
  </TextField>
</template>

<script>
import Address from '@/models/address'
import AddressDialog from '@/components/Dialogs/Address'

export default {
  name: 'address-picker-field',
  components: { AddressDialog },
  props: {
    value: {
      type: Object
    },
    type: {
      type: String
    },
    customerId: {
      type: Number,
    },
  },
  data: () => ({
    address: new Address(),
    dialog: false
  }),
  created() {
    //
  },
  methods: {
    setAddress(address) {
      this.dialog = false
      this.address = address
    },
  },
  watch: {
    value(val) {
      this.address = val
    },
    address(val) {
      this.$emit('input', this.address)
    }
  }
}
</script>