<template>
  <div class="errors">
    <v-alert
      v-for="(error, key) in errors"
      :key="key"
      dense
      outlined
      type="error"
    >
      <p
        class="ma-0 pa-0 mb-1"
        v-for="(message, idx) in error"
        :key="idx"
        v-text="$t(message)"
      />
    </v-alert>
    <v-alert v-if="message" dense outlined type="error">
      <p class="ma-0 pa-0 mb-1" v-text="$t(message)" />
    </v-alert>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: Object,
      required: false,
    },
    message: {
      type: String,
      required: false
    }
  },
}
</script>
