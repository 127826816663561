export default {
  login: {
    title: 'Login'
  },
  inbox: {
    inboxTitle: 'Inbox {name}',
    orderTabLabel: '#{orderId}'
  },
  dashboard: {
    title: 'Dashboard',
    subtitle: 'Shows inbox activity',
    openInboxButton: 'Open'
  },
  invoiceReports: {
    title: 'Reports',
    subtitle: '',
    month:'Month',
    year:'Year'
  },
  '404': {
    notFoundTitle: 'Page not found.'
  }
}