<template>
  <v-list-item-content>
    <v-list-item-title>
      {{ $t("app.orderSearch.order") }}
      {{ item.id }}
    </v-list-item-title>
    <v-list-item-subtitle>
      <span v-if="orderDate"
        >{{ $t("app.orderSearch.created") }}: {{ orderDate }}</span
      >
    </v-list-item-subtitle>

    <v-list-item-subtitle v-if="item.order.product"
      >{{ $t("app.orderSearch.product") }}:
      {{ item.order.product }}</v-list-item-subtitle
    >
    <v-list-item-subtitle v-if="item.vehicle.indicator"
      >{{ $t("app.orderSearch.indicator") }}:
      {{ item.vehicle.indicator }}</v-list-item-subtitle
    >
    <v-list-item-subtitle v-if="item.vehicle.vin"
      >{{ $t("app.orderSearch.vin") }}: {{ item.vin }}</v-list-item-subtitle
    >
  </v-list-item-content>
</template>

<script>
export default {
  name: 'search-bar-result-item',
  props: {
    item: {
      type: Object
    }
  },
  computed: {
    orderDate() {
      return this.item.createdAt ? this.formatDateStr(this.item.createdAt) : null
    }
  }
}
</script>