import { format, parseISO } from 'date-fns'

const TIME_FORMAT = 'hh:mm'
const DATE_FORMAT = 'dd.MM.yyyy'
const DATE_TIME_FORMAT = 'dd.MM.yyyy hh:mm'
const DATE_TIME24_FORMAT = 'dd.MM.yyyy HH:mm'

export default {
  methods: {
    formatDate(date: Date) {
      return format(date, DATE_FORMAT)
    },
    formatDateTime(date: Date) {
      return format(date, DATE_TIME_FORMAT)
    },
    formatDateStr(date: string) {
      return format(parseISO(date), DATE_FORMAT)
    },
    formatDateTimeStr(date: string) {
      return format(parseISO(date), DATE_TIME_FORMAT)
    },
    formatDateTimeStr24(date: string) {
      return format(parseISO(date), DATE_TIME24_FORMAT)
    },
    parseDate(date: string) {
      return parseISO(date)
    },
    getCurrentYear() {
      return format(new Date(), "yyyy")
    }
  },

  filters: {
    timeFormat(time: string) {
      return format(parseISO(time), TIME_FORMAT)
    },
    dateFormat(date: string) {
      return format(parseISO(date), DATE_FORMAT)
    },
    dateTimeFormat(dateTime: string) {
      return format(parseISO(dateTime), DATE_TIME_FORMAT)
    }
  }
}
