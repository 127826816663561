import ApiService from './base'

const AuthService = {
  user(){
    return ApiService.get('/api/ho/user')
  },
  login (params: object) {
    return ApiService.post('/extern-login', params, { withCredentials: true })
  },
  logout () {
    return ApiService.get('/extern-logout', '', { withCredentials: true })
  }
}

export default AuthService
