<template>
  <v-dialog
    ref="dateDialog"
    v-model="dialog"
    :return-value.sync="date"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <TextField
        :value="date ? formatDateStr(date) : null"
        :label="label"
        readonly
        v-bind="attrs"
        v-on="on"
        prepend-inner-icon="mdi-calendar-today"
      />
    </template>
    <v-date-picker v-model="date" scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="dialog = false">{{
        dateBackBtnLabel
      }}</v-btn>
      <v-btn text color="primary" @click="$refs.dateDialog.save(date)">{{
        dateOkBtnLabel
      }}</v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
export default {
  name: 'date-picker-field',
  props: {
    label: {
      type: String,
    },
    value: {
      required: false
    },
    initial: {
      required: false,
    }
  },
  mounted() {
    this.date = this.initial ? this.initial : this.date
  },
  data: () => ({
    dialog: false,
    date: null,
  }),
  computed: {
    dateOkBtnLabel() {
      return this.$t('form.datePicker.dateOk')
    },
    dateBackBtnLabel() {
      return this.$t('form.datePicker.dateCancel')
    },
  },
  watch: {
    initial(value) {
      this.date = this.initial ? this.initial : this.date
    },
    date(val) {
      this.$emit('input', val)
    }
  }
}
</script>