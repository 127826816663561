<template>
  <v-autocomplete v-bind="attrs" v-on="$listeners">
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />
    <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
      ><slot :name="slot" v-bind="scope"
    /></template>
  </v-autocomplete>
</template>

<script>
export default {
  computed: {
    attrs() {
      return {
        dense: this.isDesktop,
        ...this.$attrs
      }
    }
  }
}
</script>