<template>
  <Table
    :headers="header"
    :items="reports"
    :search="search"
    :loading="loading"
    :options.sync="options"
  >
    <template v-slot:item.from="{ item }">
      {{ formatDateTimeStr24(item.from) }}
    </template>
    <template v-slot:item.to="{ item }">
      {{ formatDateTimeStr24(item.to) }}
    </template>
    <template v-slot:item.action="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            color="brown darken-3"
            class="mr-2"
            @click="download(item)"
            v-on="on"
            >mdi-text-box-outline</v-icon
          >
        </template>
        <span v-text="$t('table.invoiceReports.download')" />
      </v-tooltip>
    </template>
  </Table>
</template>

<script>
import Mainbus from '@/mainbus'
import TableMixin from '@/mixins/table'
import DownloadMixin from '@/mixins/file-download'
import InvoiceReportService from '@/services/invoice-reports'
import { mapGetters } from 'vuex'

export default {
  name: 'table-invoice-reports',
  mixins: [TableMixin, DownloadMixin],
  props: {
    errorFilter: {
      type: Boolean,
      default: false,
    },
    month: {
      required: false,
      default: null
    },
    year: {
      required: false,
      default: null
    }
  },
  data: () => ({
    expanded: [],
    // stores timeout handler
    cl: null,
    options: {
      page: 1,
      itemsPerPage: 10,
    },
    reports: [],
    loadingDownload: false
  }),
  destroyed() {
    if (this.cl) {
      clearTimeout(this.cl)
    }
  },
  async mounted() {
    if (this.currentUser) {
      this.fetchReports()
    }
  },
  methods: {
    async download(data) {
      this.loadingDownload = true;
      InvoiceReportService.downloadReport(data.id)
        .finally(() => (this.loadingDownload = false))
        .then((response) =>
          this.forceFileDownload(
            response,
            data.filename,
            "application/octet-stream",
            data.dynamic_reports.type
          )
        );
    },
    async fetchReports() {
      this.loading = true
      await InvoiceReportService.fetchReports(this.currentUser.id, { month: this.month, year: this.year })
        .finally(() => this.loading = false)
        .then(response => {
          this.reports = response.data
          this.numTotal = response.data.length
        })
        .catch(error => console.log(error))
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    orderId() {
      return this.$route.params.orderId
    },
    header() {
      return [
        {
          text: this.$t('table.invoiceReports.filename'),
          align: 'left',
          sortable: true,
          value: 'dynamic_reports.name',
        },
        {
          text: this.$t('table.invoiceReports.from'),
          align: 'left',
          sortable: true,
          value: 'from',
        },
        {
          text: this.$t('table.invoiceReports.to'),
          align: 'center',
          sortable: false,
          value: 'to',
        },
        {
          text: this.$t('table.invoiceReports.interval'),
          align: 'center',
          sortable: true,
          value: 'dynamic_reports.interval',
        },
        {
          text: this.$t('table.invoiceReports.type'),
          align: 'center',
          sortable: true,
          value: 'dynamic_reports.type',
        },
        {
          text: this.$t('table.actions'),
          align: 'center',
          sortable: false,
          value: 'action',
        },
      ]
    },
  },
  watch: {
    month() {
      this.fetchReports()
    },
    year() {
      this.fetchReports()
    }
  }
}
</script>