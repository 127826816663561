export interface AddressConfig {
  //id?: number
  fax?: string
  city?: string
  note?: string
  email?: string
  title?: string
  phone?: string
  state?: string
  street?: string
  mobile?: string
  country?: string
  //company?: string
  zipcode?: string
  lastname?: string
  firstname?: string
  streetNumber?: string
}

export default class Address {
  //public id: number = 0
  public fax: string = ''
  public city: string = ''
  public note: string = ''
  public email: string = ''
  public title: string = ''
  public state: string = ''
  public phone: string = ''
  public street: string = ''
  public mobile: string = ''
  public country: string = ''
  //public company: string = ''
  public zipcode: string = ''
  public lastname: string = ''
  public firstname: string = ''
  public streetNumber: string = ''

  constructor(opts: AddressConfig) {
    Object.assign(this, opts);
  }
}