import Mainbus from '@/mainbus'

export default {
  methods: {
    showMessage(color, message) {
      Mainbus.$emit('message',
        { color, message }
      )
    },
    showSuccess(message: string) {
      this.showMessage('success', message)
    },
    showError(message: string) {
      this.showMessage('error', message)
    },
    showWarning(message: string) {
      this.showMessage('warning', message)
    },
    showInfo(message: string) {
      this.showMessage('primary', message)
    }
  }
}
