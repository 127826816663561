import ApiService from '@/services/base'
import AuthService from '@/services/auth'
import TokenService from '@/services/token'
import { LangSwitcher, ThemeSwitcher } from '@huesgesone/idp-npm-packages'

import {
  LOGIN,
  LOGOUT,
  CHECK_AUTH,
} from './actions.type'

import { SET_AUTH, PURGE_AUTH, SET_ERROR } from './mutations.type'

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!TokenService.getToken(),
  perms: {},
}

const getters = {
  currentUser(state) {
    return state.user
  },
  isAuthenticated(state) {
    return state.isAuthenticated
  },
  isUser(state) {
    return state.isAuthenticated && !state.user.admin
  },
  isAdmin(state) {
    return state.isAuthenticated && !!state.user.admin
  },
  canCreate(state) {
    return (state.isAuthenticated && state.perms.create)
  },
  canDelete(state) {
    return (state.isAuthenticated && state.perms.delete)
  },
  canDeleteDocument(state) {
    return (state.isAuthenticated && state.perms.documentDelete)
  },
  canEdit(state) {
    return (state.isAuthenticated && state.perms.edit)
  },


  authErrors(state) {
    return state.errors
  }
}

const actions = {
  [LOGIN](context, { email, password, i18n, vuetify }) {
    return new Promise(resolve => {
      AuthService.login({ email, password })
        .then(({ data }) => {
          context.commit(SET_AUTH, { ...data, i18n, vuetify })
          resolve(data)
        })
        .catch(error => {
          console.log(error)
          console.log(error.response)
          context.commit(SET_ERROR, error.response.data.errors)
        })
    })
  },

  [LOGOUT](context) {
    context.commit(PURGE_AUTH)
  },
  [CHECK_AUTH](context, { instance }) {
    return new Promise((resolve, reject) => {
      if (TokenService.getToken()) {
        AuthService.user()
          .then(({ data }) => {
            context.commit(SET_AUTH, {
              ...data,
              i18n: instance.$i18n,
              vuetify: instance.$vuetify
            })
            resolve()
          })
          .catch(() => {
            // logout on auth error
            context.commit(PURGE_AUTH)
          })
      } else {
        context.commit(PURGE_AUTH)
        reject(new Error('NO_TOKEN'))
      }
    })
  },
}

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error
  },
  [SET_AUTH](state, data) {
    state.isAuthenticated = true
    state.user = data.user
    state.perms = data.perms
    state.errors = {}
    if (data.token) {
      TokenService.saveToken(data.token)
      ApiService.setAuthToken(TokenService.getToken())
    }

    if (TokenService.getToken()) {
      LangSwitcher.loadMessages(data.i18n, data.lang)
      data.i18n.locale = data.lang
      ThemeSwitcher.loadTheme(data.vuetify)
    }
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false
    state.user = {}
    state.errors = {}
    TokenService.destroyToken()
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
