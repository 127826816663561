<template>
  <ViewContainer>
    <TextArea :value="langStr" rows="20" />
  </ViewContainer>
</template>

<script>
import En from '@/lang/en'

export default {
  data: () => ({

  }),
  methods: {
    formatTranslations(obj, prefix = []) {
      let outputs = []
      const data = Object.entries(obj)

      data.forEach(item => {
        if (typeof item[1] == 'string') {
          //console.log("pushing", outputs, [[...prefix, item[0]].join("."), item[1]])
          outputs.push([[...prefix, item[0]].join("."), item[1]])
        }
        else {
          outputs = outputs.concat(this.formatTranslations(item[1], [...prefix, item[0]]))
        }
      })
      //console.log(outputs)
      return outputs
    }
  },
  computed: {
    langStr() {
      let obj = {}
      let arr = this.formatTranslations(En, [])

      arr.forEach(e => obj[e[0]] = e[1])

      return JSON.stringify(obj, null, 1)
    }
  }
}
</script>