<template>
  <v-snackbar
    v-model="snackbar"
    :timeout="timeout"
    :color="color"
    @click="snackbar = false"
    bottom
    right
  >
    {{ message }}
  </v-snackbar>
</template>

<script>
export default {
  name: 'app-notification',
  data: () => ({
    message: '',
    snackbar: false,
    color: 'success'
  }),
  methods: {
    onMessage({ message, color }) {
      this.color = color || this.color
      this.message = message
      this.snackbar = true
    }
  },
  computed: {
    timeout() {
      return process.env.VUE_APP_NOTIFICATION_TIMEOUT || 2500
    }
  }
}
</script>
