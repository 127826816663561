<template>
  <Autocomplete
    :label="orderSearchLabel"
    :search-input.sync="search"
    :items="orders"
    :loading="loading"
    @change="openOrder"
    @keyup.enter="openResultInbox"
    v-model="order"
    item-value="id"
    item-text="id"
    prepend-inner-icon="mdi-magnify"
    hide-details
    clearable
    no-filter
    outlined
    single
    dense
  >
    <template v-slot:selection="data">
      {{ data.id }}
    </template>
    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>{{
          $t("app.orderSearch.noOrdersFound")
        }}</v-list-item-title>
      </v-list-item>
    </template>
    <template v-slot:item="{ item }">
      <ResultItem :item="item" />
    </template>
  </Autocomplete>
</template>

<script>
import Mainbus from '@/mainbus'
import OrderService from '@/services/order'
import ResultItem from './ResultItem'

export default {
  name: 'app-search-bar',
  components: { ResultItem },
  data: () => ({
    loading: false,
    search: '',
    orders: [],
    order: null,

    // min amount of chars to start searching
    numMinChars: 2,
    searchCounter: 0,
  }),
  methods: {
    openOrder(orderId) {
      this.$router.push({
        name: 'inbox',
        params: { inboxId: 0 }
      })
        .catch(() => { })
        .then(() => Mainbus.$emit('openOrder', orderId))
    },
    openResultInbox() {
      this.$router.push({
        name: 'inbox',
        params: { inboxId: 0, search: this.search }
      })
    },
    async queryOrders(query) {
      this.searchCounter += 1
      let ticketNr = this.searchCounter

      this.loading = true
      OrderService.query({ search: query })
        .finally(() => this.loading = false)
        .then(res => {
          if (this.searchCounter == ticketNr) {
            this.orders = res.data
          }
          else {
            console.log("query expired. another query is already running")
          }
        })

        .catch(err => console.log(err))
    },
    clearEntries() {
      this.orders = []
    },
    fetchEntriesDebounced(query) {
      clearTimeout(this._searchTimerId)
      this._searchTimerId = setTimeout(() => {
        this.queryOrders(query)
      }, 500) /* 500ms throttle */
    }
  },
  computed: {
    orderSearchLabel() {
      return this.$t('app.orderSearch.label')
    },
    autocompleteMenuProps() {
      let defaultProps = {
        closeOnClick: false,
        closeOnContentClick: true,
        disableKeys: true,
        openOnClick: false,
        maxHeight: 304
      };

      if (this.$vuetify.breakpoint.smAndDown) {
        defaultProps.maxHeight = 130;
        defaultProps.top = true;
      }
      return defaultProps;
    },
  },
  watch: {
    search(val) {
      if (!val) {
        this.orders = []
        return
      }

      if (val && val !== this.select && val.length >= this.numMinChars) {
        this.clearEntries()
        this.loading = true
        this.fetchEntriesDebounced(val)
      }
    },
  },
}
</script>