<template>
  <ViewContainer>
    <v-card>
      <v-card-title v-text="$t('page.invoiceReports.title')" />
      <v-card-subtitle v-text="$t('page.invoiceReports.subtitle')" />
      <v-card-text>
        <v-container>
          <v-card class="mb-2" flat>
            <v-card-text>
              <v-row>
                <v-col cols="6" md="6">
                  <SelectField
                    :items="months"
                    item-text="name"
                    item-value="month"
                    :label="monthSelectLabel"
                    v-model="month"
                    dense
                    hide-details
                    single-line
                    clearable
                    class="pr-1 calendar-week"
                    outlined
                  />
                </v-col>
                <v-col cols="6" md="6">
                  <SelectField
                    :items="yearList"
                    :label="yearSelectLabel"
                    v-model="currentYear"
                    dense
                    hide-details
                    single-line
                    class="current-year"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
          <InvoiceReportsTable :month="month" :year="currentYear" />
        </v-container>
      </v-card-text>
    </v-card>
  </ViewContainer>
</template>

<script>
import InvoiceReportsTable from '@/components/Table/InvoiceReports.vue'
import DownloadMixin from '@/mixins/file-download'

export default {
  components: {
    InvoiceReportsTable
  },
  mixins: [DownloadMixin],
  data: () => ({
    currentYear: 0,
    month: null,
  }),
  async created() {
    this.currentYear = parseInt(this.getCurrentYear())
  },
  methods: {
    downloadReport() {
      this.loadingDownload = true
      InvoiceReportsTable.downloadReport(this.currentYear, this.month)
        .finally(() => this.loadingDownload = false)
        .then(response => this.forceFileDownload(response, `EvaluationReport-${this.getDate()}`, 'application/pdf', 'pdf'))
    },
  },
  computed: {
    monthSelectLabel() {
      return this.$t("page.invoiceReports.month");
    },
    yearSelectLabel() {
      return this.$t("page.invoiceReports.currentYear");
    },
    yearList() {
      return Array(3)
        .fill(this.getCurrentYear())
        .map((year, idx) => year - idx);
    },
    months() {
      return [
        { month: 1, name: this.$t('month.january') },
        { month: 2, name: this.$t('month.february') },
        { month: 3, name: this.$t('month.march') },
        { month: 4, name: this.$t('month.april') },
        { month: 5, name: this.$t('month.may') },
        { month: 6, name: this.$t('month.june') },
        { month: 7, name: this.$t('month.july') },
        { month: 8, name: this.$t('month.august') },
        { month: 9, name: this.$t('month.september') },
        { month: 10, name: this.$t('month.october') },
        { month: 11, name: this.$t('month.november') },
        { month: 12, name: this.$t('month.december') },
      ]
    }
  }
}
</script>