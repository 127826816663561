<template>
  <v-footer absolute dense padless class="font-weight-medium" inset app>
    <v-col class="text-center" cols="12">
      {{ year() }} —
      <strong v-text="footerTitle" />
    </v-col>
  </v-footer>
</template>

<script>
export default {
  name: 'app-footer',
  methods: {
    year() {
      return new Date().getFullYear()
    },
  },
  computed: {
    footerTitle() {
      return process.env.VUE_APP_FOOTER_TITLE || 'AppName'
    }
  }
}
</script>
