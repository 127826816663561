import ApiService from './base'

export default {
  endpoint: '/api/addresses',

  query(params?: any) {
    return ApiService.query(this.endpoint, {
      params: params
    })
  },
  update(addressId: number, address: Record<string, any>) {
    return ApiService.post(`${this.endpoint}/${addressId}`, address)
  },
  create(address: Record<string, any>) {
    return ApiService.post(this.endpoint, address)
  }
}
