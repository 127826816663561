import ApiService from './base'

export default {
  identVin(image) {
    const formData = new FormData()
    const blob = ApiService.convertToBlob(image)

    formData.append('image', blob, 'vin')
    return ApiService.post('/api/getvin', formData)
  }
}
